import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeSelectorComponent } from './time-selector.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

const NG_ZORRO_MODULES = [NzButtonModule, NzSegmentedModule, NzDropDownModule];

@NgModule({
  declarations: [TimeSelectorComponent],
  imports: [CommonModule, FormsModule, SvgIconModule, ...NG_ZORRO_MODULES],
  exports: [TimeSelectorComponent],
})
export class TimeSelectorModule {}
