import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IncidentInterface, IncidentsAnalyticsInterface, SingleIncidentParams } from '@twaice-fe/shared/models';
import * as IncidentsDetectionActions from '../actions/incidents-detection.actions';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const INCIDENTS_FEATURE_KEY = 'incidents';
export const SINGLE_INCIDENT_FEATURE_KEY = 'single-incidents';

export interface State extends EntityState<IncidentInterface> {
  config: ListConfigurationInterface;
  selectedId?: string | number; // which Systems record has been selected
  isLoading: boolean; // has the Systems list been loaded
  error?: string | null; // last known error (if any)
  analytics: EntityState<IncidentsAnalyticsInterface>;
}

export interface SingleIncidentSate extends EntityState<IncidentInterface> {
  isLoading: boolean;
  error?: string | null;
  selectedId: string;
}

export interface IncidentsPartialState {
  readonly [INCIDENTS_FEATURE_KEY]: State;
}

//* Adapter
export const incidentsAdapter: EntityAdapter<IncidentInterface> = createEntityAdapter<IncidentInterface>({
  selectId: (model) => model.key,
});

export const incidentsAnalyticsAdapter: EntityAdapter<IncidentsAnalyticsInterface> =
  createEntityAdapter<IncidentsAnalyticsInterface>({
    selectId: (model) => model.systemBk,
  });

// this is a simple cache for fetched single incidents so its separated from the "incidents list" state above
export const singleIncidentsAdapter = createEntityAdapter<IncidentInterface>({ selectId: (incident) => incident.key });

//* Initial States
export const initialAnalyticsState = incidentsAnalyticsAdapter.getInitialState({
  config: {
    limit: 20,
    page: 1,
    totalPages: 1,
  },
  isLoading: false,
});

export const initialSingleIncidentState: SingleIncidentSate = singleIncidentsAdapter.getInitialState({
  isLoading: false,
  selectedId: '',
});

export const initialState: State = incidentsAdapter.getInitialState({
  analytics: {
    ...initialAnalyticsState,
  },
  config: {
    limit: 20,
    page: 1,
    totalPages: 1,
  },
  isLoading: false,
});

const incidentsReducer = createReducer(
  initialState,
  on(IncidentsDetectionActions.fetchIncidents, (state) => ({ ...state, isLoading: true, error: null })),
  on(IncidentsDetectionActions.loadIncidentsSuccess, (state, { incidents }) => {
    const adapterFn = incidents.page === 1 ? incidentsAdapter.setAll : incidentsAdapter.upsertMany;
    return adapterFn(incidents.items, {
      ...state,
      config: {
        ...state.config,
        page: incidents.page,
        limit: incidents.pageSize,
        totalPages: incidents.totalPages,
        totalItems: incidents.totalItems,
      },
      isLoading: false,
    });
  }),
  on(IncidentsDetectionActions.loadIncidentsAnalyticsSuccess, (state, { analytics }) => ({
    ...state,
    analytics: incidentsAnalyticsAdapter.upsertMany(analytics.items, {
      ...initialAnalyticsState,
      ...state.analytics,
    }),
  })),
  on(IncidentsDetectionActions.loadIncidentsFailure, (state, { error }) => ({ ...state, isLoading: false, error })),
  on(IncidentsDetectionActions.updateIncidentListConfiguration, (state, { config }) => ({
    ...state,
    config: { ...state.config, ...(config.page ? config : { ...config, page: 1 }) },
  })),
  on(IncidentsDetectionActions.incidentColumnPicker, (state, { columns }) => ({
    ...state,
    config: {
      ...state.config,
      columns,
    },
  })),
  on(IncidentsDetectionActions.sortIncidents, (state, { order }) =>
    incidentsAdapter.setAll([], {
      ...state,
      config: { ...state.config, page: 1, order },
      isLoading: true,
    })
  ),
  on(IncidentsDetectionActions.filterIncidents, changeStateFilter),
  on(IncidentsDetectionActions.filterIncidentsAnalytics, changeStateFilter),
  on(IncidentsDetectionActions.resetIncidentFilters, (state) =>
    incidentsAdapter.setAll([], {
      ...state,
      config: {
        ...state.config,
        limit: 20,
        page: 1,
        totalPages: 1,
        filter: {},
      },
      isLoading: true,
    })
  )
);

export const singleIncidentsReducer = createReducer(
  initialSingleIncidentState,
  on(IncidentsDetectionActions.fetchSingleIncident, (state, params) => ({
    ...state,
    isLoading: true,
    error: null,
    selectedId: generateSingleIncidentKey(params),
  })),
  on(IncidentsDetectionActions.loadSingleIncidentSuccess, (state, { incident }) =>
    singleIncidentsAdapter.addOne(incident, {
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(IncidentsDetectionActions.loadSingleIncidentFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return incidentsReducer(state, action);
}

function generateSingleIncidentKey(params: SingleIncidentParams) {
  const keyStartTime = params.startTime.replace('Z', '');
  return `${params.customerBk}/${params.systemBk}/${params.ruleTypeBk}/${params.ruleBk}/${params.sensorBk}/${keyStartTime}`;
}

function changeStateFilter(
  state: State,
  props: {
    filter: Record<string, string>;
  }
) {
  return incidentsAdapter.setAll([], {
    ...state,
    config: {
      ...state.config,
      page: 1,
      filter: {
        ...state.config?.filter,
        ...props.filter,
      },
    },
    isLoading: true,
  });
}
