import { Component, Input, OnChanges, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { isValidStringDate } from '@twaice-fe/shared/utilities';
import { timeAgo } from '../data-table/data-table-time-ago.utils';

/**
 * @class DataDisplayComponent
 * @description Data display component should offer a generic way of displaying data across the application
 *
 * @property {string | number | number[]}  value  - The value that is being displayed
 * @property {string}  measurand                  - The measurand of the provided value
 * @property {string}  notAvailableText           - Text displayed when the value or an array item is undefined
 * @property {string}  nullText                   - Text displayed when the value or an array item has the value of null
 * @property {string}  arrayValuesBinder          - A binder string that joins the values when provided as an array
 * @property {string}  numberFormat               - By default number format is 1.2-2 for numbers between -10 and 10 and 1.0-0 for all else
 * @property {boolean} isDate                     - When set to true, it displays the value in a default date format
 * @property {string}  dateFormat                 - When set, it displays the value as a date in defined format
 * @property {boolean} displayTimeAgo             - When value is displayed as a date, adds time ago at the end e.g." (13 minutes ago)"
 * @property {boolean}  copyable                   - When true, we display copy icon right of the value
 *                                                  and copy the value into the system's clipboard on the click of the element.
 *                                                  Single line is set to true when the item is copyable
 * @property {boolean}  singleLine                - display items in line and cut off with ellipsis
 *
 *
 *
 *
 * @example Basic usage:
 * ------------------x.component.ts----------------------------
 * value: number = 253.1243;
 * ------------------x.component.html----------------------------
 * <twaice-fe-data-display [value]="value" measurand="V"></twaice-fe-data-display>
 * ------------------DISPLAYED RESULT----------------------------
 * 253 V
 *
 *
 * @example Array usage
 * ------------------x.component.ts----------------------------
 * valueMin: number = 1.244;
 * valueMax: number = 500.234;
 * ------------------x.component.html----------------------------
 * <twaice-fe-data-display [value]="[valueMin, valueMax]" arrayValuesBinder="/"></twaice-fe-data-display>
 * ------------------DISPLAYED RESULT----------------------------
 * 1.2/500
 *
 *
 * @example Array usage 2
 * ------------------x.component.ts----------------------------
 * valueMin: number = null;
 * valueMax: number = 5;
 * ------------------x.component.html----------------------------
 * <twaice-fe-data-display [value]="[valueMin, valueMax]" arrayValuesBinder="/" measurand="%"></twaice-fe-data-display>
 * ------------------DISPLAYED RESULT----------------------------
 * -/5 %
 *
 *
 * @example Date usage
 * ------------------x.component.ts----------------------------
 * dateString: string = '2021-06-22T16:29:52.613000Z';
 * ------------------x.component.html----------------------------
 * <twaice-fe-data-display [value]="dateString" [displayTimeAgo]="true" dateFormat="d. MMMM y H:mm:ss UTC"></twaice-fe-data-display>
 * ------------------DISPLAYED RESULT----------------------------
 * 22. June 2021 18:45:49 UTC (13 minutes ago)
 *
 *
 * */

@Component({
  selector: 'twaice-fe-data-display',
  templateUrl: './data-display.component.html',
  styleUrls: ['./data-display.component.scss'],
})
export class DataDisplayComponent implements OnChanges {
  @Input() value: string | number | number[];
  @Input() measurand: string;
  @Input() notAvailableText = 'N/A';
  @Input() nullText = '-';
  @Input() arrayValuesBinder = '...';
  @Input() numberFormat: string;
  @Input() simpleNumber: boolean;

  @Input() isDate: boolean;
  @Input() dateFormat: string;
  @Input() displayTimeAgo: boolean;
  @Input() copyable: boolean;
  @Input() singleLine: boolean;
  @Input() sign: string;
  @Input() customPipe: PipeTransform;

  allowCopyAction: boolean;
  valueCopied: boolean;

  displayValue: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe
  ) {}

  ngOnChanges(): void {
    this.updateDisplayValue();
  }

  getFormattedNumber(value: number): string {
    if (this.simpleNumber) {
      return Math.round(value).toString();
    }

    if (this.numberFormat) {
      return this.decimalPipe.transform(value, this.numberFormat);
    } else {
      if (value < -10 || value > 10 || value === 0) {
        return this.decimalPipe.transform(value, '1.0-0');
      } else {
        return this.decimalPipe.transform(value, '1.1-1');
      }
    }
  }

  getSingleValueDisplay(value: unknown): string {
    // We differentiate between null and undefined -> if a value is null it is defined, but set to null
    // if the value is undefined then it is treated as missing information.
    if (this.value === null || this.value === '') {
      return null;
    }

    // if it is undefined, we return the not available text to be displayed
    if (this.value === undefined) {
      return undefined;
    }

    if (
      (this.isDate || this.dateFormat) &&
      (value instanceof Date || (typeof value === 'string' && isValidStringDate(value)) || typeof value === 'number')
    ) {
      let displayValue = this.datePipe.transform(value, this.dateFormat);

      if (this.displayTimeAgo) {
        displayValue += ` (${timeAgo(value)})`;
      }
      return displayValue;
    } else if (this.customPipe) {
      return this.customPipe.transform(value);
    } else if (typeof value === 'string') {
      // if it is a string, we simply display the value
      return value;
    } else if (typeof value === 'number') {
      // if it is a number we format it according to the specs
      return this.getFormattedNumber(value);
    }
  }

  updateDisplayValue() {
    if (Array.isArray(this.value)) {
      // We first we check in case the value is actually an array and handle it accordingly
      let anyValueFound = false;
      const transformedArrayValues: string[] = this.value.map((value: unknown) => {
        if (value === undefined) {
          return this.notAvailableText;
        } else if (value === null) {
          return this.nullText;
        } else {
          anyValueFound = true;
        }
        return this.getSingleValueDisplay(value);
      });

      if (anyValueFound) {
        this.displayValue = transformedArrayValues.join(' ' + this.arrayValuesBinder + ' ');
      } else if (this.value.length) {
        //if there is no value found, we set it to null or undefined according to what we find in the first value
        this.displayValue = this.value[0] === null ? null : undefined;
      }
    } else {
      // if it is not an array, we only have a simple single value
      this.displayValue = this.getSingleValueDisplay(this.value);
    }

    this.allowCopyAction = this.displayValue !== this.notAvailableText && this.copyable && !!this.value;
  }

  onValueCopied() {
    if (!this.allowCopyAction) {
      return;
    }

    // we want the "copied" tooltip to appears for only a few sec
    this.valueCopied = true;
    setTimeout(() => {
      this.valueCopied = false;
    }, 2000);
  }
}
