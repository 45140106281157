<div class="w-full h-[48px] flex items-center sticky top-0 z-10 bg-white border-b-1 border-b-primary-border border-solid">
  <div class="flex items-center justify-between w-full">
    <ng-container *ngIf="!isGlobal; else globalTemplate">
      <div data-type="storage-info" class="flex items-center">
        <div
          #toggleButton
          class="flex items-center gap-8 rounded-sm p-16 pl-[14px] text-sm leading-lg text-primary-text cursor-pointer"
          (click)="showStorageDropdown()"
          [attr.data-intercom-target]="intercomTarget + '-storage-dropdown-button'"
        >
          <twaice-fe-svg-icon name="icon_server" />
          <button>
            <ng-container *ngIf="(selectedStorage$ | async)?.name; else loadingTemplate">
              {{ (selectedStorage$ | async)?.name }}
            </ng-container>
            <ng-template #loadingTemplate>
              @if (noSystemsAvailable) { No storage } @else {
              <twaice-fe-loading-stripe size="sm" />
              }
            </ng-template>
          </button>
          <twaice-fe-svg-icon name="keyboard_arrow_down" source="material" />
        </div>
        <twaice-fe-time-selector *ngIf="isPerformanceManagerSolution && performanceManagerExist"></twaice-fe-time-selector>
      </div>
    </ng-container>

    <div
      *ngIf="!isFleet && !isGlobal"
      data-type="metadata"
      class="flex items-center gap-24 text-sm px-13 mr-8"
      [attr.data-intercom-target]="intercomTarget + '-energy-storage-info'"
    >
      <twaice-fe-svg-icon
        *ngIf="!isFleet && selectedStorage$ | async"
        name="icon_tree"
        class="cursor-pointer"
        size="20px"
        (click)="openMetadataDialog()"
        [attr.data-intercom-target]="intercomTarget + '-storage-metadata-button'"
      />
      <div class="flex flex-col justify-start md:flex-row md:items-center md:gap-8 leading-base text-primary-text">
        <span class="text-secondary-text">Cycles last 30 days</span>
        <twaice-fe-data-display numberFormat="1.0-0" [value]="(selectedStorage$ | async)?.kpis?.efcSum ?? '--'" />
      </div>

      <div class="flex flex-col justify-start md:flex-row md:items-center md:gap-8 leading-base text-primary-text">
        <span class="text-secondary-text">Total cycles</span>
        <twaice-fe-data-display numberFormat="1.0-0" [value]="(selectedStorage$ | async)?.kpis?.efcAvg ?? '--'" />
      </div>

      <div class="flex flex-col justify-start md:flex-row md:items-center md:gap-8 leading-base">
        <span class="text-secondary-text">Min. SoH</span>
        <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="(selectedStorage$ | async)?.kpis?.sohCMin ?? '--'" />
      </div>

      <div class="flex flex-col justify-start md:flex-row md:items-center md:gap-8 leading-base">
        <span class="text-secondary-text">Max. SoH</span>
        <twaice-fe-data-display numberFormat="1.0-0" measurand="%" [value]="(selectedStorage$ | async)?.kpis?.sohCMax ?? '--'" />
      </div>
    </div>
  </div>
</div>

<div #menu class="sticky top-0 z-10">
  <div
    class="w-full h-[300px] overflow-scroll bg-white shadow-lg absolute mt-0 transition-opacity duration-300"
    [class.hidden]="!isSystemDropdownVisible"
  >
    <twaice-fe-system-portfolio-overview-table
      [selectedSystemId]="(selectedStorage$ | async)?.id"
      [customConfig]="{ overrideMode: 'CARD' }"
      [isFleet]="isFleet"
      (rowSelected)="onRowSelected($event)"
      [attr.data-intercom-target]="intercomTarget + '-storage-dropdown-menu'"
      [intercomTarget]="intercomTarget + '-storage-dropdown-menu'"
    />
  </div>
</div>

<ng-template #globalTemplate>
  <div class="flex items-center gap-8 p-16 pl-[14px] text-sm leading-lg text-primary-text">
    <twaice-fe-svg-icon name="icon_server" /> All Storages
  </div>
</ng-template>
