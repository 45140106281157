/* eslint-disable @typescript-eslint/naming-convention */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/fe';
import { AuthService } from '@twaice-fe/frontend/shared/services';
import { from, Observable, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
  private readonly DO_NOT_INTERCEPT_PREFIX = 'do-not-intercept/';
  private readonly ASSETS_PREFIX = 'assets';
  private readonly WIDGET_PREFIX = 'widget';
  private readonly PERFORMANCE_PREFIX = 'performance-manager';


  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isAssetRequest(request.url)) {
      return next.handle(request);
    }

    return from(this.authService.getIdToken()).pipe(
      mergeMap((token: string) => {
        if (this.isDoNotInterceptRequest(request.url)) {
          return next.handle(this.modifyUrlWithoutPrefix(request));
        } else if (token) {
          return next.handle(this.addAuthorizationHeader(request, token));
        } else {
          this.authService.logout();
          // if there's no token and it's not a "do-not-intercept" request, throw an error
          return throwError(() => 'No authorization token found');
        }
      })
    );
  }

  private isAssetRequest(url: string): boolean {
    return url.startsWith(this.ASSETS_PREFIX);
  }

  private isDoNotInterceptRequest(url: string): boolean {
    return url.startsWith(this.DO_NOT_INTERCEPT_PREFIX);
  }

  private modifyUrlWithoutPrefix(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const modifiedUrl = request.url.slice(this.DO_NOT_INTERCEPT_PREFIX.length);
    return request.clone({
      url: modifiedUrl,
      withCredentials: false,
    });
  }

  private addAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    const isExternal = request.url.startsWith(this.WIDGET_PREFIX) || request.url.startsWith(this.PERFORMANCE_PREFIX)
    const baseUrl = isExternal ? environment.baseUrlApi : environment.baseUrlBe4Fe;
    return request.clone({
      url: `${baseUrl}${request.url}`,
      withCredentials: false,
      setHeaders: {
        Authorization: `bearer ${token}`,
      },
    });
  }
}
