import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import * as IncidentsDetectionActions from '../actions/incidents-detection.actions';
import * as IncidentsDetectionSelectors from '../selectors/incidents-detection.selectors';

import { Store } from '@ngrx/store';
import { IncidentsDetectionService } from '@twaice-fe/frontend/shared/services';
import { RequestQueryBuilder } from '@twaice-fe/frontend/shared/utilities';

@Injectable()
export class IncidentsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          IncidentsDetectionActions.fetchIncidents,
          IncidentsDetectionActions.updateIncidentListConfiguration,
          IncidentsDetectionActions.sortIncidents,
          IncidentsDetectionActions.filterIncidents,
          IncidentsDetectionActions.resetIncidentFilters,
        ]
      ),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getIncidentsState)),
      switchMap(([_action, state]) => {
        const { page, limit, order, filter } = state?.config ?? { page: 1, limit: 20 };

        const params = new RequestQueryBuilder().build({ page, limit, order, ...filter });
        return this.incidentsDetectionService.fetchIncidents(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsSuccess({ incidents: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[IncidentsDetectionActions.fetchIncidentsAnalytics, IncidentsDetectionActions.filterIncidentsAnalytics]),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getIncidentsState)),
      switchMap(([_, state]) => {
        const filter = state?.config?.filter ?? {};
        const params = new RequestQueryBuilder().build({ page: 1, limit: 500, ...filter });
        return this.incidentsDetectionService.fetchIncidentsAnalytics(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsAnalyticsSuccess({ analytics: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchSingleIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchSingleIncident),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getSingleIncident)),
      switchMap(([params, state]) =>
        (state ? of({ data: state }) : this.incidentsDetectionService.fetchSingleIncident(params)).pipe(
          map(({ data }) => IncidentsDetectionActions.loadSingleIncidentSuccess({ incident: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Single Incidents]', error);
            return of(IncidentsDetectionActions.loadSingleIncidentFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private incidentsDetectionService: IncidentsDetectionService,
    protected store: Store
  ) {}
}
