import { createAction, props } from '@ngrx/store';
import { IncidentDelaysInterface, IncidentRulesThresholdInterface, PaginatedResponse } from '@twaice-fe/shared/models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const fetchIncidentRules = createAction('[Incidents/Rules] Fetch incident rules');

export const filterIncidentRules = createAction(
  '[Incidents/Rules] Filter incident rules',
  props<{ filter: Record<string, string> }>()
);

export const loadIncidentRulesSuccess = createAction(
  '[Incident Rules/API] Load incident Rules Success',
  props<{ incidentsRules: PaginatedResponse<IncidentRulesThresholdInterface> }>()
);

export const loadIncidentRulesFailure = createAction('[Incident Rules/API] Load Incident Rules Failure', props<{ error: any }>());

/**
 * Datatable config actions
 */
export const updateIncidentListConfiguration = createAction(
  '[Incidents/Rules] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const sortIncidents = createAction('[Incidents/ API] Sort incidents', props<{ order: string }>());

export const incidentThresholdColumnPicker = createAction(
  '[Datatable/Config] Incidents threshold column picker',
  props<{ columns: string[] }>()
);
export const incidentDefinitionColumnPicker = createAction(
  '[Datatable/Config] Incidents definition column picker',
  props<{ columns: string[] }>()
);

/**
 * Incident delays and notifications actions
 */
export const fetchIncidentDelays = createAction('[Incidents/Delays] Fetch incidents delays', props<{ systemID: string }>());
export const loadIncidentDelaysSuccess = createAction(
  '[Incidents/Delays] load incidents delays Success',
  props<{ incidentsDelays: PaginatedResponse<IncidentDelaysInterface> }>()
);
export const loadIncidentDelayFailure = createAction(
  '[Incident Delays/API] Load Incident Rules Failure',
  props<{ error: any }>()
);

export const incidentDelaysColumnPicker = createAction(
  '[Datatable/Config] Incidents delays column picker',
  props<{ columns: string[] }>()
);

export const updateIncidentDelaysListConfiguration = createAction(
  '[Incidents/Rules] Update incidents delays table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);


export const resetIncidentRulesFilters = createAction('[Incidents/Rules] Reset Filter Incident Rules');

export const cancelIncidentRulesRequest = createAction('[Incidents/Rules] Cancel incident Rules http request');
