import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { catchError, map, mergeMap, of, takeUntil, withLatestFrom } from 'rxjs';

import { CustomerType } from '@twaice-fe/shared/models';
import * as SystemsActions from '../actions/systems.actions';
import * as ConfigSelectors from '../selectors/configs.selectors';
import * as SystemSelectors from '../selectors/systems.selectors';

@Injectable()
export class SystemsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[SystemsActions.fetchSystems, SystemsActions.fetchSystemsHealthKpiData, SystemsActions.updateSystemListConfiguration]
      ),
      withLatestFrom(this.store.select(SystemSelectors.getSystemsState), this.store.select(ConfigSelectors.getCustomerType)),
      mergeMap(([action, state, customerType]) => {
        let { page, limit } = state?.config ?? { page: 1, limit: 10 };
        limit = limit ?? 10;
        page = page ?? 1;

        const { type, ...includes } = action;
        return this.systemService
          .fetchSystems({ page, limit, ...includes, onlySystems: customerType !== CustomerType.ENERGY })
          .pipe(
            map(({ data }) =>
              type === SystemsActions.fetchSystemsHealthKpiData({}).type
                ? SystemsActions.loadSystemsHealthKpiDataSuccess({ data })
                : SystemsActions.loadSystemsSuccess({ data })
            ),
            takeUntil(this.actions$.pipe(ofType(SystemsActions.cancelSystemRequest))),
            catchError((error) => {
              console.error('Error', error);
              return of(SystemsActions.loadSystemsFailure({ error }));
            })
          );
      })
    )
  );

  systemEfcData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemsActions.fetchSystemsEfcData),
      mergeMap((action) =>
        this.systemService
          .fetchSystemEfcData(
            action.systemIds.map((obj) => new URLSearchParams({ ...obj, calculationLevel: obj.calculationLevel.toString() }))
          )
          .pipe(
            map(({ data }) => SystemsActions.loadSystemsEfcDataSuccess({ data })),
            catchError((error) => {
              console.error('Error', error);
              return of(SystemsActions.loadSystemsFailure({ error }));
            })
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    protected store: Store,
    private systemService: SystemsService
  ) {}
}
