import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as performanceManagerActions from '../actions/performance-manager.actions';
import * as performanceManagerSelectors from '../selectors/performance-manager.selectors';
import { Store } from '@ngrx/store';
import { PerformanceManagerService } from '@twaice-fe/frontend/shared/services';
import * as sharedStore from '../index';
import { PerformanceManagerKPI } from '@twaice-fe/shared/models';

const { systemSelectors } = sharedStore.selectors;

@Injectable()
export class PerformanceManagerEffects {
  fetchSystemStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemStatistics),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSystemStatistics)
      ),
      filter(([, system]) => system != null),
      switchMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemStatistics({ ...params, customerBk: system?.customerId, systemBk: system?.rootContainerId })
          .pipe(
            map((systemStatistics) => performanceManagerActions.loadSystemStatisticsSuccess({ systemStatistics })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemStatisticsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system statistics]', error);
              return of(performanceManagerActions.loadSystemStatisticsFailure({ error }));
            })
          )
      )
    )
  );

  fetchSystemKPIData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemKPIData),
      withLatestFrom(this.store.select(systemSelectors.getSelected), this.store.select(performanceManagerSelectors.getSystemKPI)),
      filter(([, system]) => system != null),
      mergeMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemKPIData({ ...params, customerBk: system?.customerId, systemBk: system?.rootContainerId })
          .pipe(
            map((systemKPIData) => performanceManagerActions.loadSystemKPIDataSuccess({ systemKPIData })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemKPIDataRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system KPI data]', error);
              return of(performanceManagerActions.loadSystemKPIDataFailure({ error }));
            })
          )
      )
    )
  );

  fetchSystemInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemInsights),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      concatMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemInsights({ ...params, customerBk: system?.customerId, systemBk: system?.rootContainerId })
          .pipe(
            map((systemInsights) => {
              if (PerformanceManagerKPI.TEMPERATURE_MAX === params.kpi) {
                return performanceManagerActions.loadSystemInsightsSuccess({
                  systemInsights: {
                    temperature: {
                      [PerformanceManagerKPI.TEMPERATURE_MAX]: systemInsights,
                    },
                  },
                });
              }

              if (PerformanceManagerKPI.TEMPERATURE_SPREAD === params.kpi) {
                return performanceManagerActions.loadSystemInsightsSuccess({
                  systemInsights: {
                    temperature: {
                      [PerformanceManagerKPI.TEMPERATURE_SPREAD]: systemInsights,
                    },
                  },
                });
              }

              return performanceManagerActions.loadSystemInsightsSuccess({
                systemInsights: {
                  balancing: systemInsights,
                },
              });
            }),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemInsightsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system insights data]', error);
              return of(performanceManagerActions.loadSystemInsightsFailure({ error }));
            })
          )
      )
    )
  );

  fetchSystemSeverityLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemSeverityLevel),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSystemSeverityLevel)
      ),
      filter(([, system]) => system != null),
      switchMap(([_, system]) =>
        this.performanceManagerService
          .fetchSystemSeverityLevel({ customerBk: system?.customerId, systemBk: system?.rootContainerId })
          .pipe(
            map(({ data }) => performanceManagerActions.loadSystemSeverityLevelSuccess({ systemSeverityLevel: data })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemSeverityLevelRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system severity level data]', error);
              return of(performanceManagerActions.loadSystemSeverityLevelFailure({ error }));
            })
          )
      )
    )
  );

  fetchStringKPIData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchStringKPIData),
      withLatestFrom(this.store.select(systemSelectors.getSelected), this.store.select(performanceManagerSelectors.getStringKPI)),
      filter(([, system]) => system != null),
      switchMap(([params, system]) =>
        this.performanceManagerService
          .fetchStringKPIData({ ...params, customerBk: system?.customerId, systemBk: system?.rootContainerId })
          .pipe(
            map((stringKPIData) => performanceManagerActions.loadStringKPIDataSuccess({ stringKPIData })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelStringKPIDataRequest))),
            catchError((error) => {
              console.error('[Error/Fetch string KPI data]', error);
              return of(performanceManagerActions.loadStringKPIDataFailure({ error }));
            })
          )
      )
    )
  );

  fetchEnergyLossData$ = createEffect(() => this.actions$.pipe(
      ofType(performanceManagerActions.fetchEnergyLossData),
      switchMap(() => this.performanceManagerService
        .fetchEnergyLossData()
        .pipe(
          map(({ data }) => performanceManagerActions.loadEnergyLossSuccess({ energyLossData: data }) ),
          catchError((error) => {
            console.error('[Error/Fetch energy loss]', error);
            return of(performanceManagerActions.loadEnergyLossFailure({ error }));
          })
        )
      )
    )
  
  );

  constructor(
    private readonly actions$: Actions,
    private performanceManagerService: PerformanceManagerService,
    protected store: Store
  ) { }
}
