<mat-card class="alerts-overview rounded-lg m-0">
  <mat-card-content class="p-24">
    <div *ngIf="incidentsCount$ | async as incidentsCount" class="flex items-center gap-8 flex-wrap">
      <div class="flex items-start flex-col gap-8 pl-16 pr-24 py-24">
        <span class="text-base leading-lg font-semibold text-primary-text whitespace-nowrap gap-8">{{
          'Overview' | translate: { _key: 'fleet.alerts-overview.total' }
        }}</span>
        <div class="flex items-start flex-col">
          <twaice-fe-data-display
            class="card-primary-text"
            [value]="incidentsCount?.incidents?.total"
            notAvailableText="-"
            numberFormat="1.0-0"
          ></twaice-fe-data-display>
          <span>
            <span class="card-secondary-text">
              {{ 'of' | translate: { _key: 'fleet.alerts-overview.filter-difference-label' } }}
            </span>
            <twaice-fe-data-display
              class="card-secondary-text"
              [value]="incidentsCount?.totalIncidents?.total"
              notAvailableText="-"
              numberFormat="1.0-0"
            ></twaice-fe-data-display>
          </span>
        </div>
      </div>
      <button
        nz-button
        nzType="default"
        class="flex items-center justify-between flex-col gap-8 px-36 py-24 flex-1 box-border h-full"
        [ngClass]="{ 'button-active': activeFilters?.severity === incidentSeverityEnum.CRITICAL }"
        (click)="setSeverityFilter(incidentSeverityEnum.CRITICAL)"
      >
        <div class="flex items-center gap-8">
          <twaice-fe-svg-icon name="icon_severity_critical" />
          <span class="card-title">{{ 'Critical' | translate: { _key: 'fleet.alerts-overview.critical' } }}</span>
        </div>
        <div class="flex items-center flex-col">
          <twaice-fe-data-display
            class="card-primary-text"
            [value]="incidentsCount?.incidents?.critical"
            notAvailableText="-"
            numberFormat="1.0-0"
          ></twaice-fe-data-display>
          <span>
            <span class="card-secondary-text">
              {{ 'of' | translate: { _key: 'fleet.alerts-overview.filter-difference-label' } }}
            </span>
            <twaice-fe-data-display
              class="card-secondary-text"
              [value]="incidentsCount?.totalIncidents?.critical"
              notAvailableText="-"
              numberFormat="1.0-0"
            ></twaice-fe-data-display
          ></span>
        </div>
      </button>
      <button
        nz-button
        nzType="default"
        class="flex items-center justify-between flex-col gap-8 px-36 py-24 flex-1 box-border h-full"
        [ngClass]="{ 'button-active': activeFilters?.severity === incidentSeverityEnum.HIGH }"
        (click)="setSeverityFilter(incidentSeverityEnum.HIGH)"
      >
        <div class="flex items-center gap-8">
          <twaice-fe-svg-icon name="icon_severity_high" />
          <span class="card-title">{{ 'High' | translate: { _key: 'fleet.alerts-overview.high' } }}</span>
        </div>
        <div class="flex items-center flex-col">
          <twaice-fe-data-display
            class="card-primary-text"
            [value]="incidentsCount?.incidents?.high"
            notAvailableText="-"
            numberFormat="1.0-0"
          ></twaice-fe-data-display>
          <span>
            <span class="card-secondary-text">
              {{ 'of' | translate: { _key: 'fleet.alerts-overview.filter-difference-label' } }}
            </span>
            <twaice-fe-data-display
              class="card-secondary-text"
              [value]="incidentsCount?.totalIncidents?.high"
              notAvailableText="-"
              numberFormat="1.0-0"
            ></twaice-fe-data-display>
          </span>
        </div>
      </button>
      <button
        nz-button
        nzType="default"
        class="flex items-center justify-between flex-col gap-8 px-36 py-24 flex-1 box-border h-full"
        [ngClass]="{ 'button-active': activeFilters?.severity === incidentSeverityEnum.MEDIUM }"
        (click)="setSeverityFilter(incidentSeverityEnum.MEDIUM)"
      >
        <div class="flex items-center gap-8">
          <twaice-fe-svg-icon name="icon_severity_significant" />
          <span class="card-title">{{ 'Medium' | translate: { _key: 'fleet.alerts-overview.medium' } }}</span>
        </div>
        <div class="flex items-center flex-col">
          <twaice-fe-data-display
            class="card-primary-text"
            [value]="incidentsCount?.incidents?.medium"
            notAvailableText="-"
            numberFormat="1.0-0"
          ></twaice-fe-data-display>
          <span>
            <span class="card-secondary-text">
              {{ 'of' | translate: { _key: 'fleet.alerts-overview.filter-difference-label' } }}
            </span>
            <twaice-fe-data-display
              class="card-secondary-text"
              [value]="incidentsCount?.totalIncidents?.medium"
              notAvailableText="-"
              numberFormat="1.0-0"
            ></twaice-fe-data-display>
          </span>
        </div>
      </button>
      <button
        nz-button
        nzType="default"
        class="flex items-center justify-between flex-col gap-8 px-36 py-24 flex-1 box-border h-full"
        [ngClass]="{ 'button-active': activeFilters?.severity === incidentSeverityEnum.LOW }"
        (click)="setSeverityFilter(incidentSeverityEnum.LOW)"
      >
        <div class="flex items-center gap-8">
          <twaice-fe-svg-icon name="icon_severity_minor" />
          <span class="card-title">{{ 'Low' | translate: { _key: 'fleet.alerts-overview.low' } }}</span>
        </div>
        <div class="flex items-center flex-col">
          <twaice-fe-data-display
            class="card-primary-text"
            [value]="incidentsCount?.incidents?.low"
            notAvailableText="-"
            numberFormat="1.0-0"
          ></twaice-fe-data-display>
          <span>
            <span class="card-secondary-text">
              {{ 'of' | translate: { _key: 'fleet.alerts-overview.filter-difference-label' } }}
            </span>
            <twaice-fe-data-display
              class="card-secondary-text"
              [value]="incidentsCount?.totalIncidents?.low"
              notAvailableText="-"
              numberFormat="1.0-0"
            ></twaice-fe-data-display>
          </span>
        </div>
      </button>
    </div>
  </mat-card-content>
</mat-card>
