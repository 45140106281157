import { Injectable } from '@angular/core';
import { CurrentEnvironment } from '@twaice-fe/environments/shared';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class SingleSignOnService {
  loginWithInternalMicrosoft365() {
    Auth.federatedSignIn({
      customProvider: CurrentEnvironment.getEnvironmentVariable().awsConfig.federatedSignIn.identityProvider,
    });
  }

  isSingleSignOnEnabled(): boolean {
    const federatedSignIn = CurrentEnvironment.getEnvironmentVariable().awsConfig.federatedSignIn;
    if (!federatedSignIn) return false;

    return federatedSignIn.allowedHost === window.location.host;
  }
}
