import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PERFORMANCE_MANAGER_FEATURE_KEY, State, systemStatisticsAdapter, energyLossAdapter } from '../reducers/performance-manager.reducer';

export const getPerformanceManagerState = createFeatureSelector<State>(PERFORMANCE_MANAGER_FEATURE_KEY);
const statisticsAdapter = systemStatisticsAdapter.getSelectors();
export const getSystemStatistics = createSelector(getPerformanceManagerState, (state) =>
  statisticsAdapter.selectAll(state.systemStatistics.data)
);
export const getLatestDaySystemStatistics = createSelector(getSystemStatistics, (entities) => {
  const sortedEntities = [...entities].sort((a, b) => b.x - a.x);
  const latestStatistics = sortedEntities.find((stats) => stats.y['high'] || stats.y['medium'] || stats.y['balanced']);
  return latestStatistics ? latestStatistics.x : null;
});
export const getSystemStatisticsError = createSelector(getPerformanceManagerState, (state) => state.systemStatistics.error);
export const getSystemKPI = createSelector(getPerformanceManagerState, (state) => state.systemKPIData);
export const getSystemKPIError = createSelector(
  getPerformanceManagerState,
  (state) => state.systemKPIData.inverter.error ?? state.systemKPIData.string.error
);

export const getSystemInsights = createSelector(getPerformanceManagerState, (state) => state.systemInsights);
export const getSystemSeverityLevel = createSelector(getPerformanceManagerState, (state) => state.systemSeverityLevel);
export const getSystemSeverityLevelError = createSelector(getPerformanceManagerState, (state) => state.systemSeverityLevel.error);

export const getStringKPI = createSelector(getPerformanceManagerState, (state) => state.stringKPIData);
export const getStringKPIError = createSelector(getPerformanceManagerState, (state) => state.stringKPIData.error);


export const getEnergyLossState = createSelector(getPerformanceManagerState, (state) => state.energyLoss);
export const getEnergyLossBySystem = (systemBk: string) => createSelector(getEnergyLossState, ({ data: { entities } }) => entities[systemBk])