import { ExtendedEnvironment } from './environment-variables/environment.model';
/*
 * This class is here to provide a library level environment variable
 * to decuple the environment from the application and therefore avoid
 * the circular dependency and enable shared services and components
 * to make use of the environment variable.
 *
 * To make it useful set the environment variable on the application level
 * preferably in the constructor of app module
 * */

export class CurrentEnvironment {
  private static ENVIRONMENT;

  static setEnvironmentVariable(environment: ExtendedEnvironment) {
    this.ENVIRONMENT = environment;
  }

  static getEnvironmentVariable(): ExtendedEnvironment {
    return this.ENVIRONMENT;
  }
}
