import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  INCIDENTS_FEATURE_KEY,
  SINGLE_INCIDENT_FEATURE_KEY,
  SingleIncidentSate,
  State,
  incidentsAdapter,
  incidentsAnalyticsAdapter,
} from '../reducers/incidents-detection.reducer';
import { IncidentsAnalyticsInterface, IncidentsCountInterface } from '@twaice-fe/shared/models';

export const getIncidentsState = createFeatureSelector<State>(INCIDENTS_FEATURE_KEY);
export const getSingleIncidentsState = createFeatureSelector<SingleIncidentSate>(SINGLE_INCIDENT_FEATURE_KEY);
const { selectAll, selectEntities } = incidentsAdapter.getSelectors();
const analyticsAdapter = incidentsAnalyticsAdapter.getSelectors();

export const getIncidentsList = createSelector(getIncidentsState, (state: State) => selectAll(state));
export const getIncidentsEntities = createSelector(getIncidentsState, (state: State) => selectEntities(state));
export const getSelectedId = createSelector(getIncidentsState, (state: State) => state.selectedId);
export const getSelected = createSelector(getIncidentsEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getIncidentsConfig = createSelector(getIncidentsState, (state: State) => state.config);

export const getIncidentsAnalytics = createSelector(getIncidentsState, (state) => analyticsAdapter.selectAll(state.analytics));

export const getIncidentsCount = createSelector(getIncidentsState, (state) => {
  const analytics = analyticsAdapter.selectAll(state.analytics);
  const incidents = groupIncidents(analytics, 'incidents');
  const totalIncidents = groupIncidents(analytics, 'totalIncidents');
  return { incidents, totalIncidents };
});

export const getSingleIncident = createSelector(getSingleIncidentsState, (state) => state.entities[state.selectedId]);
export const getSingleIncidentError = createSelector(getSingleIncidentsState, (state) => state.error);

const groupIncidents = (incidentsAnalytics: IncidentsAnalyticsInterface[], incidentObj: 'incidents' | 'totalIncidents') =>
  incidentsAnalytics.reduce(
    (grouped, actual) => {
      grouped.critical += actual[incidentObj].critical;
      grouped.high += actual[incidentObj].high;
      grouped.medium += actual[incidentObj].medium;
      grouped.low += actual[incidentObj].low;

      grouped.total = grouped.total ?? 0;
      grouped.total +=
        actual[incidentObj].critical + actual[incidentObj].high + actual[incidentObj].medium + actual[incidentObj].low;
      return grouped;
    },
    { low: 0, critical: 0, high: 0, medium: 0, total: 0 } as IncidentsCountInterface
  );
