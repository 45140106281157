import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagsEnum } from '@twaice-fe/shared/models';
import { CONFIGS_FEATURE_KEY, State } from '../reducers/config.reducer';

export const getConfigsState = createFeatureSelector<State>(CONFIGS_FEATURE_KEY);

export const getConfigFeatureFlagList = createSelector(getConfigsState, (state: State) => state.featureFlags);
export const getAvailableSolutionList = createSelector(getConfigsState, (state: State) => state.solutions);

export const getCustomerType = createSelector(getConfigsState, (state: State) => state.customerType);
export const getCustomerBk = createSelector(getConfigsState, (state: State) => state.customerBk);
export const getBaseUrl = createSelector(getConfigsState, (state: State) => state.baseUrl);

export const getIncidentFeatureState = createSelector(
  getConfigFeatureFlagList,
  (featureList) =>
    featureList.filter((featureFlags) => featureFlags?.includes(FeatureFlagsEnum.HEALTH_INCIDENTS_OVERVIEW)).length > 0
);
