import { createAction, props } from '@ngrx/store';
import {
  IncidentInterface,
  IncidentsAnalyticsInterface,
  PaginatedResponse,
  SingleIncidentParams,
} from '@twaice-fe/shared/models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const fetchIncidents = createAction('[Incidents] Fetch incident log');
export const fetchIncidentsAnalytics = createAction('[Incidents] Fetch incident log analytics');
export const fetchSingleIncident = createAction('[Incidents] Fetch single incident', props<SingleIncidentParams>());

export const loadIncidentsSuccess = createAction(
  '[Incidents/API] Load incidents Success',
  props<{ incidents: PaginatedResponse<IncidentInterface> }>()
);

export const loadIncidentsAnalyticsSuccess = createAction(
  '[Incidents/API] Load incidents analytics Success',
  props<{ analytics: PaginatedResponse<IncidentsAnalyticsInterface> }>()
);

export const loadSingleIncidentSuccess = createAction(
  '[Incidents/API] Load single incident Success',
  props<{ incident: IncidentInterface }>()
);

export const loadIncidentsFailure = createAction('[Incidents/API] Load Incidents Failure', props<{ error: any }>());
export const loadSingleIncidentFailure = createAction('[Incidents/API] Load Single Incident Failure', props<{ error: any }>());

export const updateIncidentListConfiguration = createAction(
  '[Incidents] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const cancelIncidentsRequest = createAction('[Incidents/API] Cancel incidents http request');

export const sortIncidents = createAction('[Incidents/ API] Sort incidents', props<{ order: string }>());

export const filterIncidents = createAction('[Incidents/ API] Filter incidents', props<{ filter: Record<string, string> }>());

export const filterIncidentsAnalytics = createAction('[Incidents/ API] Filter incidents analytics', props<{ filter: Record<string, string> }>());

export const resetIncidentFilters = createAction('[Incidents/ API] Reset Filter Incidents');

export const incidentColumnPicker = createAction('[Datatable/Config] Incidents column picker', props<{ columns: string[] }>());
