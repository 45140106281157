import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  IncidentDelaysInterface,
  IncidentRulesDefinitionType,
  IncidentRulesThresholdInterface,
  IncidentThresholdType,
  PaginatedResponse,
} from '@twaice-fe/shared/models';
import * as IncidentRulesActions from '../actions/incidents-rules.actions';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const INCIDENTS_RULES_FEATURE_KEY = 'incidents-rules';
export const INCIDENTS_RULES_THRESHOLD_FEATURE_KEY = 'incidents-rules-threshold';
export const INCIDENTS_RULES_DEFINITION_FEATURE_KEY = 'incidents-rules-definition';
export const INCIDENTS_RULES_DELAYS_FEATURE_KEY = 'incidents-rules-delays';

export interface DatatableStateInterface<T> extends EntityState<T> {
  config: ListConfigurationInterface;
  selectedId?: string | number; // which Systems record has been selected
  isLoading: boolean; // has the Systems list been loaded
  error?: string | null; // last known error (if any)
}

//* Adapter
export const incidentsThresholdAdapter: EntityAdapter<IncidentThresholdType> = createEntityAdapter<IncidentThresholdType>({
  selectId: (model) => `${model.customerBk}-${model.systemBk}-${model.ruleTypeBk}-${model.ruleBk}`,
});

export const incidentsDefinitionAdapter: EntityAdapter<IncidentRulesDefinitionType> =
  createEntityAdapter<IncidentRulesDefinitionType>({
    selectId: (model) => `${model.customerBk}-${model.systemBk}-${model.ruleTypeBk}-${model.ruleBk}`,
  });

export const incidentsDelaysAdapter: EntityAdapter<IncidentDelaysInterface> = createEntityAdapter<IncidentDelaysInterface>();

export interface State {
  thresholdRules: DatatableStateInterface<IncidentThresholdType>;
  incidentRulesDefinitions: DatatableStateInterface<IncidentRulesDefinitionType>;
  incidentDelayNotifications: DatatableStateInterface<IncidentDelaysInterface>;
}

const createInitialState = (adapter: EntityAdapter<any>) =>
  adapter.getInitialState({
    config: {
      limit: 20,
      page: 1,
      totalPages: 1,
    },
    isLoading: false,
  });

export const initialState: State = {
  thresholdRules: createInitialState(incidentsThresholdAdapter),
  incidentRulesDefinitions: createInitialState(incidentsDefinitionAdapter),
  incidentDelayNotifications: createInitialState(incidentsDelaysAdapter),
};

// Refactor data filtering for reusability
const transformIncidentData = <T>(
  incidentRules: PaginatedResponse<IncidentRulesThresholdInterface>,
  transformFn: (item: IncidentRulesThresholdInterface) => T
): PaginatedResponse<T> =>
  new PaginatedResponse({
    ...incidentRules,
    items: incidentRules.items.map(transformFn),
  });

const transformThreshold = (obj: IncidentRulesThresholdInterface): IncidentThresholdType => obj;

const transformDefinition = ({
  name,
  sensorType,
  numberOfBreaches,
  windowLength,
  level,
  customerBk,
  systemBk,
  ruleTypeBk,
  ruleBk,
}: IncidentRulesThresholdInterface): IncidentRulesDefinitionType => ({
  name,
  sensorType,
  numberOfBreaches,
  windowLength,
  level,
  customerBk,
  systemBk,
  ruleTypeBk,
  ruleBk,
});

// IncidentThresholdType | IncidentRulesDefinitionType
// Utility function to set data into the state
const setDatatableData = (adapter: any, state: DatatableStateInterface<any>, incidentsRules: PaginatedResponse<any>) => {
  const adapterFn = incidentsRules.page === 1 ? adapter.setAll : adapter.upsertMany;
  return adapterFn(incidentsRules.items, {
    ...state,
    config: {
      ...state.config,
      page: incidentsRules.page,
      limit: incidentsRules.pageSize,
      totalPages: incidentsRules.totalPages,
      totalItems: incidentsRules.totalItems,
    },
    isLoading: false,
  });
};

const incidentsReducer = createReducer(
  initialState,
  on(IncidentRulesActions.fetchIncidentRules, (state) => ({
    ...state,
    thresholdRules: { ...state.thresholdRules, isLoading: true, error: null },
    incidentRulesDefinitions: { ...state.incidentRulesDefinitions, isLoading: true, error: null },
  })),
  on(IncidentRulesActions.filterIncidentRules, (state, { filter }) => ({
    ...state,
    thresholdRules: incidentsThresholdAdapter.setAll([], {
      ...state.thresholdRules,
      isLoading: true,
      error: null,
      config: {
        ...state.thresholdRules?.config,
        filter: {
          ...state.thresholdRules?.config?.filter,
          ...filter,
        },
        page: 1,
      },
    }),
  })),
  on(IncidentRulesActions.loadIncidentRulesSuccess, (state, { incidentsRules }) => ({
    ...state,
    thresholdRules: setDatatableData(
      incidentsThresholdAdapter,
      state.thresholdRules,
      transformIncidentData(incidentsRules, transformThreshold)
    ),
    incidentRulesDefinitions: setDatatableData(
      incidentsDefinitionAdapter,
      state.incidentRulesDefinitions,
      transformIncidentData(incidentsRules, transformDefinition)
    ),
  })),
  on(IncidentRulesActions.loadIncidentDelaysSuccess, (state, { incidentsDelays }) => ({
    ...state,
    incidentDelayNotifications: incidentsDelaysAdapter.setAll(incidentsDelays.items, {
      ...state.incidentDelayNotifications,
      config: {
        ...state.incidentDelayNotifications.config,
        page: incidentsDelays.page,
        limit: incidentsDelays.pageSize,
        totalPages: incidentsDelays.totalPages,
        totalItems: incidentsDelays.totalItems,
      },
      isLoading: false,
    }),
  })),
  on(IncidentRulesActions.loadIncidentRulesFailure, (state, { error }) => ({
    ...state,
    thresholdRules: incidentsThresholdAdapter.setAll([], {
      ...state.thresholdRules,
      isLoading: false,
      error,
    }),
    incidentRulesDefinitions: incidentsDefinitionAdapter.setAll([], {
      ...state.incidentRulesDefinitions,
      isLoading: false,
      error,
    }),
  })),
  on(IncidentRulesActions.loadIncidentDelayFailure, (state, { error }) => ({
    ...state,
    incidentDelayNotifications: incidentsDelaysAdapter.setAll([], {
      ...state.incidentDelayNotifications,
      isLoading: false,
      error,
    }),
  })),
  on(IncidentRulesActions.updateIncidentListConfiguration, (state, { config }) => ({
    ...state,
    thresholdRules: {
      ...state.thresholdRules,
      config: { ...state.thresholdRules.config, ...(config.page ? config : { ...config, page: 1 }) },
    },
    incidentRulesDefinitions: {
      ...state.incidentRulesDefinitions,
      config: { ...state.incidentRulesDefinitions.config, ...(config.page ? config : { ...config, page: 1 }) },
    },
  })),
  on(IncidentRulesActions.incidentThresholdColumnPicker, (state, { columns }) => ({
    ...state,
    thresholdRules: {
      ...state.thresholdRules,
      config: {
        ...state.thresholdRules.config,
        columns,
      },
    },
  })),
  on(IncidentRulesActions.incidentDefinitionColumnPicker, (state, { columns }) => ({
    ...state,
    incidentRulesDefinitions: {
      ...state.incidentRulesDefinitions,
      config: {
        ...state.incidentRulesDefinitions.config,
        columns,
      },
    },
  })),
  on(IncidentRulesActions.incidentDelaysColumnPicker, (state, { columns }) => ({
    ...state,
    incidentDelayNotifications: {
      ...state.incidentDelayNotifications,
      config: {
        ...state.incidentDelayNotifications.config,
        columns,
      },
    },
  })),
  on(IncidentRulesActions.updateIncidentDelaysListConfiguration, (state, { config }) => ({
    ...state,
    incidentDelayNotifications: {
      ...state.incidentDelayNotifications,
      config: { ...state.incidentDelayNotifications.config, ...(config.page ? config : { ...config, page: 1 }) },
    },
  })),
  on(IncidentRulesActions.sortIncidents, (state, { order }) => ({
    ...state,
  })),
  on(IncidentRulesActions.resetIncidentRulesFilters, (state) => ({
    ...state,
    thresholdRules: incidentsThresholdAdapter.setAll([], {
      ...state.thresholdRules,
      isLoading: true,
      error: null,
      config: {
        ...state.thresholdRules?.config,
        filter: {},
        page: 1,
      },
    }),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return incidentsReducer(state, action);
}
