import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { WarrantyTrackerInterface } from '@twaice-fe/shared/models';

import * as warrantyTrackerActions from '../actions/warranty-tracker.actions';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const WARRANTY_TRACKER_FEATURE_KEY = 'warranty-tracker';

export interface State extends EntityState<WarrantyTrackerInterface> {
  config: ListConfigurationInterface;
  selectedId?: string;
  isLoading: boolean;
  error?: string | null;
}

export const warrantyAdapter: EntityAdapter<WarrantyTrackerInterface> = createEntityAdapter<WarrantyTrackerInterface>();
export const initialState: State = warrantyAdapter.getInitialState({
  selectedId: '',
  config: {
    limit: 500,
    page: 1,
    totalPages: 1,
    columns: [],
  },
  isLoading: false,
});

const warrantyReducer = createReducer(
  initialState,
  on(warrantyTrackerActions.fetchWarrantyData, (state) =>
    warrantyAdapter.setAll([], {
      ...state,
      isLoading: true,
      error: null,
    })
  ),
  on(warrantyTrackerActions.loadWarrantyDataSuccess, (state, { data }) =>
    warrantyAdapter.setAll(data.items, {
      ...state,
      config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
      isLoading: false,
    })
  ),

  on(warrantyTrackerActions.loadWarrantyFailure, (state, { error }) => ({ ...state, ...initialState, error, isLoading: false })),
  on(warrantyTrackerActions.updateWarrantyListConfiguration, (state, { config, isLoading }) => ({
    ...state,
    isLoading: isLoading ?? false,
    config: { ...state.config, ...config },
  })),
  on(warrantyTrackerActions.warrantyListColumnPicker, (state, { columns }) => ({
    ...state,
    config: {
      ...state.config,
      columns,
    },
  })),
  on(warrantyTrackerActions.sortWarrantyTracker, (state, { order }) => ({
    ...state,
    config: {
      ...state.config,
      order,
    },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return warrantyReducer(state, action);
}
