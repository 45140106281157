import { AwsConfigs, DatabricksConfig, Environment } from './environment.model';
import { REGION } from './shared/region';

export const AWS_CONFIGS_STG: AwsConfigs = {
  default: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_B2GdzTbYt',
    aws_user_pools_web_client_id: '5n00u4f3coovnj3opr8n48aji6',
  },
  daimlerbus: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_eOBHxHO1h',
    aws_user_pools_web_client_id: '384lbli2ebemmu0ot41aoe8q8p',
    federatedSignIn: {
      identityProvider: 'DaimlerbusOIDCProvider',
      domain: 'daimlerbus-auth.stg.twaice.cloud',
      allowedHost: 'daimlerbus.stg.twaice.cloud',
      redirectUrl: 'https://daimlerbus.stg.twaice.cloud/',
    },
  },
  internal_sso: {
    aws_project_region: REGION,
    aws_user_pools_id: 'eu-west-1_dGnCKzWGT',
    aws_user_pools_web_client_id: '4h52ccgfcldsldjqrjkruek6m8',
    federatedSignIn: {
      identityProvider: 'Microsoft365EntraIdProvider',
      domain: 'internal-auth.stg.twaice.cloud',
      allowedHost: 'internal.stg.twaice.cloud',
      redirectUrl: 'https://internal.stg.twaice.cloud/',
    },
  },
};

export const DATABRICKS_CONFIG_STG: DatabricksConfig = {
  tokenArn: '/twaice/databricks/workspaces/stg_fe_token',
  pathArn: '/twaice/databricks/workspaces/stg/warehouses/stg-backend-sql-warehouse/http_path',
  hostArn: '/twaice/databricks/workspaces/stg/warehouses/stg-backend-sql-warehouse/server_hostname',
  databaseNames: {
    measurements: 'stg_dl_measurements',
    battery_kpis: 'stg_dl_battery_kpis',
    customer_config: 'stg_dl_customer_config',
    performance_manager: 'stg_dl_performance_manager',
  },
};

export const ENVIRONMENT_STG: Environment = {
  production: false,
  name: process.env['ENV_NAME'] || 'stg',
  staticFilesHost: 'https://static.stg.twaice.ai/',
  baseUrlBe4Fe: 'https://be4fe.stg.twaice.ai/',
  baseUrlApi: 'https://api.stg.twaice.ai/',
  uiConfigTableName: 'stg-ui-config-table',
  awsPinpoint: {
    appId: 'dc045457b6214b32882595af87d087a1',
    region: REGION,
    mandatorySignIn: true,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
  // public identifier for the Transifex project
  transifexToken: '1/e60edf51d3cbc5c2a847caef792f9da32ba18ef6',
  // public Hotjar site ID
  hotjarSiteId: '3508799',
  // public Intercom App ID
  intercomAppId: 'thagvi83',
  // public Mixpanel ID
  mixpanelId: '4f213a56d72f86ef826d5cc8b1ed19e9',
  identityPoolAuth: {
    identityPoolId: 'eu-west-1:23485f1c-5c18-4360-82dd-37f656a1483f',
    region: REGION,
    identityPoolRegion: REGION,
  },
};
