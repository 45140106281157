import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { environment } from '@env/fe';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';

import { TranslationService, TxNativeModule } from '@transifex/angular';
import { CurrentEnvironment } from '@twaice-fe/environments/shared';
import { AuthService, IncidentsDetectionService, SystemsService, UserTrackingService } from '@twaice-fe/frontend/shared/services';
import {
  DemoLinkModule,
  DemoTagModule,
  FooterModule,
  NavigationMenuModule,
  SideNavLayoutModule,
  SvgIconModule,
} from '@twaice-fe/frontend/shared/ui-components';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DashboardLayoutComponent } from './containers/dashboard-layout/dashboard-layout.component';
import { ToolbarComponent } from './containers/dashboard-layout/toolbar/toolbar.component';
import { AuthorizationTokenInterceptor } from './utilities/authorisation.interceptor';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { MissingConfigurationComponent } from './views/error/missing-configuration.component';
import { SolutionModule } from './views/solution/solution.module';
import { SubPageModule } from './views/sub-page/sub-page.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { clearStateMetaReducer, effects, localStorageSyncReducer, reducers } from '@twaice-fe/frontend/shared/store';

const { systemReducer, incidentsDetectionReducer, configsReducer, incidentRulesReducer, authReducer, performanceManagerReducer } = reducers;
const { SystemsEffects, AuthEffects, IncidentsEffects, IncidentRulesEffects, PerformanceManagerEffects } = effects;

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UserTrackingEffects } from 'libs/frontend/shared/store/src/effects';
import { en_GB, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { DashboardSideNavComponent } from './containers/dashboard-layout/sidenav/dashboard-sidenav.component';
import { RequestedSystemsInterceptor } from './utilities/requested-systems.interceptor';
import { AnalyticsUpsellComponent } from './views/analytics-upsell/analytics-upsell.component';

export function initApp(auth: AuthService, systemService: SystemsService) {
  return async () => {
    try {
      await auth.waitForAuthComplete();

      if (auth.isLoggedIn()) {
        try {
          await systemService.getCurrentSystemUiConfiguration();
        } catch (error) {
          console.error('[AppModule] Error while preparing router configuration: ', error);
        }
      }
    } catch (error) {
      console.error('[AppModule] Error waiting for auth completion:', error);
    }

    return true;
  };
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);

export const metaReducers: MetaReducer[] = [clearStateMetaReducer, localStorageSyncReducer];

const COMMON = [BrowserModule, BrowserAnimationsModule, RouterModule, AppRoutingModule, HttpClientModule, CommonModule];

const MATERIAL_MODULES = [
  MatTabsModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
];

const INTERNAL_MODULES = [
  FeatureToggleModule,
  FooterModule,
  SubPageModule,
  SolutionModule,
  DemoTagModule,
  MatDividerModule,
  SvgIconModule,
  NavigationMenuModule,
  SideNavLayoutModule,
  DemoLinkModule,
];

const ANT_MODULES = [
  NzLayoutModule,
  NzMessageModule,
  NzMenuModule,
  NzToolTipModule,
  NzLayoutModule,
  NzDropDownModule,
  NzButtonModule,
  NzTagModule,
  NzCardModule,
  NzTabsModule,
  NzBadgeModule,
];

@NgModule({
  imports: [
    TxNativeModule.forRoot(),
    ...COMMON,
    ...MATERIAL_MODULES,
    ...INTERNAL_MODULES,
    ...ANT_MODULES,
    StoreModule.forRoot(
      {
        router: routerReducer,
        [systemReducer.SYSTEMS_FEATURE_KEY]: systemReducer.reducer,
        [incidentsDetectionReducer.INCIDENTS_FEATURE_KEY]: incidentsDetectionReducer.reducer,
        [incidentsDetectionReducer.SINGLE_INCIDENT_FEATURE_KEY]: incidentsDetectionReducer.singleIncidentsReducer,
        [configsReducer.CONFIGS_FEATURE_KEY]: configsReducer.reducer,
        [incidentRulesReducer.INCIDENTS_RULES_FEATURE_KEY]: incidentRulesReducer.reducer,
        [authReducer.AUTH_FEATURE_KEY]: authReducer.reducer,
      },
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([SystemsEffects, AuthEffects, IncidentsEffects, IncidentRulesEffects, UserTrackingEffects]),
    StoreModule.forFeature(
      performanceManagerReducer.PERFORMANCE_MANAGER_FEATURE_KEY,
      performanceManagerReducer.performanceManagerReducer
    ),
    EffectsModule.forFeature([PerformanceManagerEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    MissingConfigurationComponent,
    DashboardLayoutComponent,
    DashboardSideNavComponent,
    ToolbarComponent,
    AnalyticsUpsellComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [
        AuthService,
        SystemsService,
        // With including this, we enable user tracking for the given application
        UserTrackingService,
        Sentry.TraceService,
      ],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestedSystemsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationTokenInterceptor,
      multi: true,
    },
    IncidentsDetectionService,
    { provide: NZ_I18N, useValue: en_GB },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translationService: TranslationService,
    private userTrackingService: UserTrackingService
  ) {
    /*
     * Setting up the environment variable to support environment variable usage
     * also within the libraries (avoiding circular dependencies)
     * */
    CurrentEnvironment.setEnvironmentVariable(environment);

    // init Transifex config
    this.translationService.init({
      token: environment.transifexToken,
    });

    this.userTrackingService.setupHotjarTracking(environment.hotjarSiteId);
    this.userTrackingService.setupMixpanelTracking(environment.mixpanelId);
    this.userTrackingService.setupIntercomIntegration(environment.intercomAppId);
  }
}
