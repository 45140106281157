import { AwsConfigs, DatabricksConfig, Environment } from './environment.model';

const REGION = 'eu-west-1';

export const AWS_CONFIGS_PLACEHOLDER: AwsConfigs = {
  default: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: '{AWS_USER_POOLS_ID_REPLACE_ME}',
    aws_user_pools_web_client_id: '{AWS_USER_POOLS_WEB_CLIENT_ID_REPLACE_ME}',
  },
  daimlerbus: {
    /* eslint-disable @typescript-eslint/naming-convention -- aws defined */
    aws_project_region: REGION,
    aws_user_pools_id: '{DAIMLERBUS_AWS_USER_POOLS_ID_REPLACE_ME}',
    aws_user_pools_web_client_id: '{DAIMLERBUS_AWS_USER_POOLS_WEB_CLIENT_ID_REPLACE_ME}',
    federatedSignIn: {
      identityProvider: '{DAIMLERBUS_FEDERATED_IDENTITY_PROVIDER_REPLACE_ME}',
      domain: '{DAIMLERBUS_FEDERATED_SIGN_IN_URL_REPLACE_ME}',
      allowedHost: '{DAIMLERBUS_FEDERATED_MS_SIGN_IN_ALLOWED_HOST_REPLACE_ME}',
      redirectUrl: '{DAIMLERBUS_FEDERATED_MS_REDIRECT_URL_REPLACE_ME}',
    },
  },
  internal_sso: {
    aws_project_region: REGION,
    aws_user_pools_id: '{INTERNAL_SSO_AWS_USER_POOLS_ID_REPLACE_ME}',
    aws_user_pools_web_client_id: '{INTERNAL_SSO_AWS_USER_POOLS_WEB_CLIENT_ID_REPLACE_ME}',
    federatedSignIn: {
      identityProvider: '{INTERNAL_SSO_FEDERATED_IDENTITY_PROVIDER_REPLACE_ME}',
      domain: '{INTERNAL_SSO_FEDERATED_SIGN_IN_URL_REPLACE_ME}',
      allowedHost: '{INTERNAL_SSO_FEDERATED_MS_SIGN_IN_ALLOWED_HOST_REPLACE_ME}',
      redirectUrl: '{INTERNAL_SSO_FEDERATED_MS_REDIRECT_URL_REPLACE_ME}',
    },
  },
};

export const DATABRICKS_CONFIG_PLACEHOLDER: DatabricksConfig = {
  tokenArn: '{DATABRICKS_TOKEN_ARN}',
  pathArn: '{DATABRICKS_PATH_ARN}',
  hostArn: '{DATABRICKS_HOST_ARN}',
  databaseNames: {
    measurements: '{DATABRICKS_DATABASE_NAME_MEASUREMENTS}',
    battery_kpis: '{DATABRICKS_DATABASE_NAME_BATTERY_KPIS}',
    customer_config: '{DATABRICKS_DATABASE_NAME_CUSTOMER_CONFIG}',
    performance_manager: '{DATABRICKS_DATABASE_NAME_PERFORMANCE_MANAGER}',
  },
};

export const ENVIRONMENT_PLACEHOLDER: Environment = {
  production: true,
  name: '{ENV_NAME_REPLACE_ME}',
  staticFilesHost: '{STATIC_FILES_HOST_REPLACE_ME}',
  baseUrlBe4Fe: '{BASE_URL_BE4FE_REPLACE_ME}',
  baseUrlApi: '{BASE_URL_API_REPLACE_ME}',
  uiConfigTableName: '{UI_CONFIG_TABLE_NAME_REPLACE_ME}',
  awsPinpoint: {
    appId: '{AWS_PINPOINT_APP_ID_REPLACE_ME}',
    region: REGION,
    mandatorySignIn: true,
  },
  /* eslint-enable @typescript-eslint/naming-convention */
  // public identifier for the Transifex project
  transifexToken: '{TRANSIFEX_PROJECT_TOKEN_REPLACE_ME}',
  // public Hotjar site ID
  hotjarSiteId: '{HOTJAR_SITE_ID_REPLACE_ME}',
  // public Intercom App ID
  intercomAppId: '{INTERCOM_APP_ID_REPLACE_ME}',
  // public Mixpanel ID
  mixpanelId: '{MIXPANEL_ID_REPLACE_ME}',
  identityPoolAuth: {
    identityPoolId: '{AWS_AUTH_IDENTITY_POOL_ID_REPLACE_ME}',
    region: REGION,
    identityPoolRegion: REGION,
  },
};
