import { StackedBarInfo } from '@twaice-fe/frontend/shared/utilities';
import { PerformanceManagerKPI } from '@twaice-fe/shared/models';

export enum SeverityEnum {
  CRITICAL = 'high',
  ELEVATED = 'medium',
  NORMAL = 'balanced',
  MISSING = 'missing',
}

export enum TemperatureSeverityEnum {
  CRITICAL = 'critical',
  ELEVATED = 'elevated',
  NORMAL = 'optimal',
  LOW = 'low',
  MISSING = 'missing',
}

export const stackedBarInfo: StackedBarInfo = {
  [SeverityEnum.CRITICAL]: {
    color: 'rgb(255,0,0)',
    label: 'High imbalances',
    thresholdText: '-',
  },
  [SeverityEnum.ELEVATED]: {
    color: 'rgb(240, 228, 66)',
    label: 'Medium imbalances',
    thresholdText: '-',
  },
  [SeverityEnum.NORMAL]: {
    color: 'rgb(0,158,115)',
    label: 'Balanced',
    thresholdText: '-',
  },
  [SeverityEnum.MISSING]: { color: 'rgba(0, 0, 0, 0.04)', label: 'Missing data' },
};

export interface KPIInfoInterface {
  [key: string]: { label: string; unit?: string; visualisationThresholds: KPIVisualisationThresholds, visualMapPrecision?: number };
}

export interface KPIVisualisationThresholds {
  [SeverityEnum.CRITICAL]: number;
  [SeverityEnum.ELEVATED]: number;
  [SeverityEnum.NORMAL]: number;
  [SeverityEnum.MISSING]: number;
}

export const kpiInfo: KPIInfoInterface = {
  [PerformanceManagerKPI.VOLTAGE_SPREAD]: {
    label: 'Voltage spread',
    unit: 'V',
    visualisationThresholds: {
      [SeverityEnum.CRITICAL]: 0.2,
      [SeverityEnum.ELEVATED]: 0.05,
      [SeverityEnum.NORMAL]: 0,
      [SeverityEnum.MISSING]: 0,
    },
    visualMapPrecision: 2,
  },
  [PerformanceManagerKPI.SOC_SPREAD]: {
    label: 'SoC spread',
    unit: '%',
    visualisationThresholds: {
      [SeverityEnum.CRITICAL]: 100,
      [SeverityEnum.ELEVATED]: 15,
      [SeverityEnum.NORMAL]: 0,
      [SeverityEnum.MISSING]: 0,
    },
  },
  [PerformanceManagerKPI.TEMPERATURE_MAX]: {
    label: 'Temperature max',
    unit: '°C',
    visualisationThresholds: {
      [SeverityEnum.CRITICAL]: 100,
      [SeverityEnum.ELEVATED]: 30,
      [SeverityEnum.NORMAL]: 5,
      [SeverityEnum.MISSING]: 0,
    },
  },
};
