import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { environment } from '@env/fe';
import { Store } from '@ngrx/store';
import { AuthService } from '@twaice-fe/frontend/shared/services';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ANALYTICS_UPSELL_ROUTE,
  ENERGY_ASSET_DASHBOARD_ROUTE,
  ENERGY_INCIDENTS_ROUTE,
  ENERGY_MANAGE_PERFORMANCE_ROUTE,
  ENERGY_OVERVIEW_ROUTE,
  MOBILITY_BASE_ROUTE,
  MOBILITY_DEPRECATED_BASE_ROUTE,
  MODEL_LIBRARY_BASE_ROUTE,
  ON_DEMAND_SERVICES_ROUTE,
  PRIVACY_POLICY_ROUTE,
  REPORTING_ROUTE,
} from '@twaice-fe/shared/constants';
import { CustomerType, FeatureFlagsEnum } from '@twaice-fe/shared/models';
import { isEqual } from '@twaice-fe/shared/utilities';
import { distinctUntilChanged, filter, switchMap } from 'rxjs';

const { incidentsDetectionActions } = actions;
const { configsSelectors } = selectors;
@Component({
  selector: 'twaice-fe-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  appVersion: string;
  isIncidentOverviewFeatureEnabled = false;
  showNavigationMenu = false;
  showGlobalNavigatioMenu = false;
  isFleet = false;
  isPerformanceManagerSolution = false;
  isAssetDashboard = false

  private customerType: CustomerType;

  constructor(
    private destroy$: DestroyRef,
    protected store: Store,
    private authService: AuthService,
    private router: Router
  ) {
    this.appVersion = environment.version;

    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent.type === EventType.NavigationEnd),
        switchMap(() => this.authService.isInternalUser()),
        takeUntilDestroyed(this.destroy$)
      )
      .subscribe((isInternalUser) => {
        this.isFleet = this.router.url.includes(MOBILITY_BASE_ROUTE) || this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE);
        this.isPerformanceManagerSolution = this.router.url.includes(ENERGY_MANAGE_PERFORMANCE_ROUTE);
        this.isAssetDashboard = this.router.url.includes(ENERGY_ASSET_DASHBOARD_ROUTE);
        this.showNavigationMenu = !this.isSolutionWithoutNavigationMenu();
        this.showGlobalNavigatioMenu = this.isSolutionWithGlobalSystemScope();

        // for internal users in fleet we always show the navigation menut
        if (isInternalUser && this.router.url.includes(MOBILITY_BASE_ROUTE)) {
          this.showNavigationMenu = true;
        }
      });

    this.store
      .select(configsSelectors.getCustomerType)
      .pipe(takeUntilDestroyed())
      .subscribe((customerType) => (this.customerType = customerType));
  }

  ngOnInit(): void {
    this.subscribeToFeatureFlags();
  }

  ngOnDestroy(): void {
    if (this.isIncidentOverviewFeatureEnabled) {
      this.store.dispatch(incidentsDetectionActions.cancelIncidentsRequest());
    }
  }

  private subscribeToFeatureFlags(): void {
    this.store
      .select(configsSelectors.getConfigFeatureFlagList)
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((featureFlags: FeatureFlagsEnum[]) => {
        if (featureFlags.includes(FeatureFlagsEnum.HEALTH_INCIDENTS_OVERVIEW)) {
          this.isIncidentOverviewFeatureEnabled = true;
          this.store.dispatch(incidentsDetectionActions.fetchIncidents());
          this.store.dispatch(incidentsDetectionActions.fetchIncidentsAnalytics());
        }
      });
  }

  private isSolutionWithGlobalSystemScope(): boolean {
    return (
      this.router.url.includes(ENERGY_INCIDENTS_ROUTE) ||
      this.router.url.includes(REPORTING_ROUTE) ||
      this.router.url.includes(ON_DEMAND_SERVICES_ROUTE) ||
      this.router.url.includes(MODEL_LIBRARY_BASE_ROUTE)
    );
  }

  private isSolutionWithoutNavigationMenu(): boolean {
    return (
      this.isSolutionWithGlobalSystemScope() ||
      this.router.url.includes(ENERGY_OVERVIEW_ROUTE) ||
      this.router.url.includes(ANALYTICS_UPSELL_ROUTE) ||
      this.router.url.includes(MOBILITY_BASE_ROUTE) ||
      this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE) ||
      (this.router.url.includes(REPORTING_ROUTE) && this.customerType === CustomerType.MOBILITY) ||
      this.router.url.includes(PRIVACY_POLICY_ROUTE) ||
      this.router.url.includes(ACCOUNT_SETTINGS_ROUTE)
    );
  }
}
