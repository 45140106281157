/**
 * Energy
 */
export const ENERGY_BASE_ROUTE = '/energy';
export const ENERGY_OVERVIEW_ROUTE = '/energy/overview';
export const ENERGY_HEALTH_ROUTE = '/energy/health';
export const ENERGY_WARRANTY_ROUTE = '/energy/warranty';
export const ENERGY_MANAGE_PERFORMANCE_ROUTE = '/performance-manager';
export const ENERGY_ASSET_DASHBOARD_ROUTE = '/energy/overview';
export const ENERGY_PREDICTION_ROUTE = '/health-prediction/health-prediction';
export const ENERGY_INCIDENTS_ROUTE = '/incidents';

/**
 * Mobility
 */
export const MOBILITY_BASE_ROUTE = '/mobility';
export const MOBILITY_FLEETS_ROUTE = '/mobility/groups';
export const MOBILITY_INCIDENTS_ROUTE = '/mobility/alerts';
export const MOBILITY_RULES_ROUTE = '/mobility/rules';
export const MOBILITY_CONFIGURATION_ROUTE = '/mobility/config';
// @deprecated since 09.03.24
export const MOBILITY_DEPRECATED_BASE_ROUTE = '/fleet-analytics';

/**
 * Model Library
 */
export const MODEL_LIBRARY_BASE_ROUTE = '/model-library/library';

/**
 * Shared
 */
export const DATA_EXPLORER_ROUTE = '/storage-monitoring/data-explorer';
export const REPORTING_ROUTE = '/reporting/library';
export const ON_DEMAND_SERVICES_ROUTE = '/on-demand-services/library';
export const ANALYTICS_UPSELL_ROUTE = '/analytics';
export const ACCOUNT_SETTINGS_ROUTE = '/settings';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
