import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { selectors } from '@twaice-fe/frontend/shared/store';
import {EnergyAnalyticsMetaData, OverviewSystem, Solution, System} from '@twaice-fe/shared/models';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { StorageMetadataDialogComponent } from './storage-metadata-dialog/storage-metadata-dialog.component';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

const { systemSelectors, authSelectors, configsSelectors } = selectors;

@Component({
  selector: 'twaice-fe-navigation-menu',
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  @Input() isFleet = false;
  @Input() isGlobal = false;
  @Input() isPerformanceManagerSolution = false;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  isSystemDropdownVisible = false;
  noSystemsAvailable = false;

  storages: EnergyAnalyticsMetaData[];
  selectedSystem: OverviewSystem;

  selectedStorage$: BehaviorSubject<EnergyAnalyticsMetaData> = new BehaviorSubject(undefined);
  destroy$ = new Subject<void>();
  intercomTarget = 'dashboard-layout-navigation-menu';
  performanceManagerExist = false;

  private destroyWindowClickListener: () => void;

  constructor(
    private dialog: MatDialog,
    private systemsService: SystemsService,
    protected store: Store,
    private renderer: Renderer2
  ) {
    // handle click outside of dropdown
    this.destroyWindowClickListener = this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !(this.toggleButton?.nativeElement as HTMLElement)?.contains(e.target as Node) &&
        e.target !== this.menu.nativeElement
      ) {
        this.showStorageDropdown(false);
      }
    });

    // TODO: Remove when solution is available for all energy customers
    this.store
      .select(configsSelectors.getAvailableSolutionList)
      .pipe(takeUntilDestroyed())
      .subscribe((solutions) => {
        this.performanceManagerExist = solutions.includes(Solution.PERFORMANCE_MANAGER);
      });
  }

  ngOnInit(): void {
    this.setSelectedSystemFromRoute();
  }

  setSelectedSystemFromRoute() {
    this.store
      .select(systemSelectors.selectSystemByRoute)
      .pipe(
        tap((selected) => {
          this.handleMissingSystemInRoute(selected);
        }),
        filter((selected) => !!selected?.id),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        switchMap((selectedSystem) => {
          if (selectedSystem) {
            this.systemsService.setCurrentSystem(selectedSystem);
            return of(selectedSystem);
          }

          return this.systemsService.getCurrentSystem();
        }),
        filter((selectedSystem) => !!selectedSystem),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedSystem) => {
        this.selectedSystem = selectedSystem;
        this.setStorages();
      });
  }

  handleMissingSystemInRoute(selected: System) {
    if (selected) {
      return;
    }
    this.store
      .select(systemSelectors.getSystemList)
      .pipe(
        withLatestFrom(this.store.select(authSelectors.getUser)),
        // TODO: Refactor. This filter is needed to avoid bug ticket MOB-20
        filter(([, user]) => !!user),
        map(([systems]) => systems[0]),
        take(1),
        tap((currentSystem) => {
          this.systemsService.setCurrentSystem(currentSystem);

          // handle dummy system inserted if user has access to no systems
          if (currentSystem?.id === '') {
            this.noSystemsAvailable = true;
          }
        })
      )
      .subscribe();
  }

  setStorages() {
    if (this.storages?.length > 0) {
      this.setSelectedStorage();
      return;
    }

    this.store.select(systemSelectors.getSystemList).subscribe((result) => {
      this.storages = result;
      this.setSelectedStorage();
    });
  }

  setSelectedStorage() {
    if (!this.selectedSystem) return;
    this.selectedStorage$.next(this.storages?.find((storage) => storage.id === this.selectedSystem.id));
  }

  async onRowSelected(item: System) {
    await this.systemsService.setCurrentSystem(item);

    this.selectedSystem = item;
    this.setSelectedStorage();
  }

  showStorageDropdown(state?: boolean) {
    this.isSystemDropdownVisible = state ?? !this.isSystemDropdownVisible;
  }

  openMetadataDialog() {
    this.dialog.open(StorageMetadataDialogComponent, {
      data: this.selectedStorage$.getValue().metadata,
    });
  }

  keepOrder() {
    return 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.renderer.destroy();
    this.destroyWindowClickListener();
  }
}
