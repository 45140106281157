import { IsBooleanString, IsDateString, IsDefined, IsNumberString } from '@twaice-fe/shared/utilities';
import { TWResponse } from './response.models';

export enum MeasurementOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum MeasurementAggregation {
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  SUM = 'sum',
  COUNT = 'count',
}

export class MeasurementsParameters {
  @IsDefined()
  tids: string[];
  @IsDefined()
  @IsDateString()
  // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
  start_range?: string;
  @IsDateString()
  // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
  end_range?: string;
  sampling?: string;
  @IsNumberString()
  limit?: number;
  order?: MeasurementOrder;
  agg?: MeasurementAggregation;
  @IsBooleanString()
  // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
  filter_outlier?: boolean; // default is false
  applySpecificOutlierFilter?: boolean; // default is false
}

export class Measurements {
  tid?: string;
  timestamps?: string[];
  values: number[];
  sampling?: string;
}

export class MeasurementsResponse extends TWResponse {
  data: Measurements[];
}
