/*
 * Conventional values of time length in ms
 * */

import {
  SYSTEM_ID_ENERGY_DEMO,
  SYSTEM_ID_ENERGY_DEMO_UK,
  SYSTEM_ID_ENERGY_US_DEMO_1,
  SYSTEM_ID_ENERGY_US_DEMO_2,
  SYSTEM_ID_ENERGY_US_DEMO_3,
  SYSTEM_ID_PERCIVAL,
} from '@twaice-fe/shared/constants';

export enum TimeLength {
  ZERO = 0,
  SECOND = 1000,
  HALF_MINUTE = 30000,
  MINUTE = 60000,
  QUARTER_HOUR = 900000,
  HOUR = 3600000,
  QUARTER_DAY = 21600000,
  HALF_DAY = 43200000,
  DAY = 86400000,
  WEEK = 604800000,
}

export enum TimeInterval {
  ZERO = 0,
  SECOND = 1000,
  HALF_MINUTE = 30000,
  MINUTE = 60000,
  QUARTER_HOUR = 900000,
  HOUR = 3600000,
  QUARTER_DAY = 21600000,
  HALF_DAY = 43200000,
  DAY = 86400000,
  SEVEN_DAYS = 604800000,
  THIRTY_DAYS = 2592000000,
  ONE_WEEK = 604800000,
  THREE_MONTHS = 7890000000,
  SIX_MONTHS = 15780000000,
  NINE_MONTHS = 23670000000,
  ONE_YEAR = 31536000000,
  THREE_YEARS = 31536000000 * 3,
}
export const getTodayBasedOnSystemID = (systemID: string): number => {
  switch (systemID) {
    // Workaround for Sembcorp
    case SYSTEM_ID_PERCIVAL:
      return Date.parse('2022-10-20T00:00:00');

    // Workaround for energy demo
    case SYSTEM_ID_ENERGY_DEMO:
      return Date.parse('2022-09-01T00:00:00');

    // Workaround for energy demo uk
    case SYSTEM_ID_ENERGY_DEMO_UK:
      return Date.parse('2023-12-01T00:00:00');

    // Workaround for energy demo us
    case SYSTEM_ID_ENERGY_US_DEMO_1:
    case SYSTEM_ID_ENERGY_US_DEMO_2:
    case SYSTEM_ID_ENERGY_US_DEMO_3:
      return Date.parse('2024-03-24T00:00:00');

    default:
      return Date.now();
  }
};

export const getTimeRangeBasedOnSystemID = (
  systemID: string,
  timeResolution: TimeInterval
): { startDate: string; endDate: string } => {
  const today = getTodayBasedOnSystemID(systemID);

  return {
    startDate: new Date(today - timeResolution).toISOString().slice(0, 19),
    endDate: new Date(today).toISOString().slice(0, 19),
  };
};

export const getTimeRangeBasedOnSystemIDInUnixTimestamp = (
  systemID: string,
  timeResolution: TimeInterval
): { startDate: number; endDate: number } => {
  const today = getTodayBasedOnSystemID(systemID);

  return {
    startDate: today - timeResolution,
    endDate: today,
  };
};

export const getCurrentDateUnixTimestamp = () => {
  const now = new Date();
  const utcMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
  return Math.floor(utcMidnight.getTime() / 1000);
};

export const getCurrentDateUnixTimestampBasedOnSystemID = (systemID: string): number => {
  switch (systemID) {
    case SYSTEM_ID_ENERGY_US_DEMO_1:
      return 1711922400;
    case SYSTEM_ID_ENERGY_US_DEMO_2:
      return 1711065600;

    default:
      return getCurrentDateUnixTimestamp();
  }
};
