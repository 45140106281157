import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { environment } from '@env/fe';
import Hotjar from '@hotjar/browser';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { User } from '@twaice-fe/shared/models';
import { Analytics } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import { userTrackingActions } from 'libs/frontend/shared/store/src/actions';
import mixpanel from 'mixpanel-browser';
import { distinctUntilChanged, filter, from, map, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieConsentService } from './cookie-consent.service';

const { configsSelectors, authSelectors } = selectors;

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  private pinpointInitialised: boolean;
  private defaultUserAttributes: {
    username: string;
    customerID: string;
    internalUser: string; // flag that determines if the user is internal or not
    version: string; // application version
  };

  constructor(
    private authService: AuthService,
    private cookieConsentService: CookieConsentService,
    private store: Store,
    private router: Router
  ) {
    this.setUpUserChangeEvents();
  }

  /**
   * This event enables custom event tracking.
   * You can add any custom event attributes that can be then filtered by on Pinpoint.
   *
   * Besides all the custom event attributes every event also goes out with the default
   * user attributes: username, customerID, (application) version
   * and internalUser(which indicates the user comes from Twaice)
   *
   * @example - Basic usage in a component
   * this.userTrackingService( 'containersSelected',{ selectAllUsed: true }, 'dataExplorer');
   *
   * @param {string} eventName - Name of the event - useCamelCase - e.g. buttonClick
   * @param {object} customEventAttributes - Custom attributes
   * @param {string} componentName - Name of the component that the event originates from- useCamelCase - e.g. dataExplorer,
   *
   * */
  trackEvent(eventName: string, customEventAttributes: unknown = {}, componentName?: string) {
    if (typeof customEventAttributes !== 'object') {
      console.error('ERROR: Events only accept event attributes of type object');
      return;
    }

    Analytics.record({
      name: (componentName ? componentName + '-' : '') + name,
      attributes: {
        component: componentName ?? 'unknown',
        ...this.defaultUserAttributes,
        ...customEventAttributes,
      },
    });
  }

  setupMixpanelTracking(token: string): void {
    if (!token) return;

    mixpanel.init(token, { debug: false, track_pageview: 'url-with-path', persistence: 'localStorage' });

    this.cookieConsentService
      .getChoices()
      .pipe(distinctUntilChanged())
      .subscribe((optIn) => {
        if (optIn === false) {
          mixpanel.opt_out_tracking();
          return;
        }

        this.store.dispatch(userTrackingActions.mixpanelSetup());
      });
  }

  setupHotjarTracking(hotjarSiteId: string): void {
    if (!hotjarSiteId) return;

    this.cookieConsentService.getChoices().subscribe((optIn) => {
      if (optIn === false) return;
      Hotjar.init(Number(hotjarSiteId), 6);

      // send an event to identify internal vs external users
      // we wait 5s here to make sure the session is valid and to wait for hotjar to be loaded
      const isInternalUser = this.authService.getUsername().includes('@twaice.com');
      setTimeout(() => {
        if (isInternalUser) {
          Hotjar.event('is_internal_user');
        } else {
          Hotjar.event('is_external_user');
        }
      }, 5000);
    });
  }

  setupIntercomIntegration(intercomAppId: string): void {
    if (!intercomAppId) return;

    this.store
      .select(authSelectors.getUser)
      .pipe(
        filter((user) => !!user),
        switchMap(() => this.store.select(configsSelectors.getCustomerType)),
        filter((customerType) => !!customerType),
        switchMap((customerType) =>
          from(this.authService.getIdToken()).pipe(
            map((token) => {
              const intercomUserHash = jwtDecode(token)?.['intercom_user_hash'];
              // Intercom provided integration script --> ignore formatting & disable typechecking
              // taken from: https://developers.intercom.com/installing-intercom/web/installation/
              // @ts-ignore
              // prettier-ignore
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src = 'https://widget.intercom.io/widget/' + intercomAppId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

              const userName = this.authService.getUsername();
              (window as any).Intercom('boot', {
                app_id: intercomAppId,
                api_base: 'https://api-iam.eu.intercom.io',
                user_hash: intercomUserHash,
                ...(userName && { email: userName }),
                ...(customerType && { customer_type: customerType }),
              });
            })
          )
        ),
        switchMap(() => this.router.events),
        tap((event) => {
          if (!(event instanceof NavigationEnd)) return;

          const userName = this.authService.getUsername();
          (window as any).Intercom('update', {
            ...(userName && { email: userName }),
          });
        }),
        switchMap(() => this.authService.getUserObservable()),
        tap((user) => {
          if (user) return;

          // end session if user logs out
          (window as any).Intercom('shutdown');
        })
      )
      .subscribe();
  }

  private setUpUserChangeEvents() {
    this.authService.getUserObservable().subscribe((user: User) => {
      this.setDefaultUserAttributes(user);
      // we only need to initialise pinpoint once
      if (!this.pinpointInitialised && user) {
        this.setUpUserTracking();
      } else if (this.pinpointInitialised && !user) {
        // We do not want to track anything when the user is logged out
        Analytics.disable();
      } else if (this.pinpointInitialised && user) {
        // if there is a new user and pinpoint was already initialised, we enable the tracking
        Analytics.enable();
      }
    });
  }

  private setDefaultUserAttributes(user: User) {
    if (!user) {
      this.defaultUserAttributes = null;
      return;
    }

    // set up the default user attributes that we can then filter by on pinpoint
    this.defaultUserAttributes = {
      username: user.username,
      customerID: user.customerID,
      // we are checking any @twaice.com users or twaiceoffice for office 360 provider logins
      internalUser: String(
        user.username.toLowerCase().includes('@twaice.com') || user.username.toLowerCase().includes('twaiceoffice')
      ),
      version: environment.version,
    };
  }

  private setPageViewTracking() {
    if (window.location.pathname !== '/') {
      // If we are logged in and  land directly to an existing webpage, the first pageView will not be automatically recorded.
      // Triggering this event is a workaround to enable us to still record the landing page activity
      Analytics.record({
        name: 'pageView',
        attributes: {
          url: window.location.pathname,
          landing: 'true',
          ...this.defaultUserAttributes,
        },
      });
    }

    Analytics.autoTrack('pageView', {
      enable: true,
      eventName: 'pageView',
      attributes: {
        ...this.defaultUserAttributes,
      },
      type: 'SPA', // 'SPA' -> single-page app
      provider: 'AWSPinpoint',
      getUrl: () => window.location.pathname,
    });
  }

  private setSessionTracking() {
    Analytics.autoTrack('session', {
      enable: true,
      attributes: {
        ...this.defaultUserAttributes,
      },
      provider: 'AWSPinpoint',
    });
  }

  private setUpUserTracking() {
    if (environment.awsConfig.pinpointDisabled) {
      return;
    }

    if (environment.identityPoolAuth) {
      //Initialize Pinpoint and the required auth
      Auth.configure(environment.identityPoolAuth);
    }

    const pinpointConfig = Analytics.configure({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      AWSPinpoint: {
        ...environment.awsPinpoint,
        /*
         * If the endpoint ID is not set, then a new endpoint is set for each new device/browser
         * (which also results in new endpoint being created for each new E2E execution)
         * There is a hard limit of 15 endpoints per cognito user -> With defining this, we are using the same "endpoint"
         * for each time the user is using Twaice UI regardless of the device/browser they are using.
         * */
        endpointId: 'twaice-ui--' + (this.defaultUserAttributes ? this.defaultUserAttributes.username : 'unknown'),
      },
    });

    // if the
    if (pinpointConfig) {
      // We set up the automatically tracked sessions and page views
      this.setPageViewTracking();
      this.setSessionTracking();
      this.pinpointInitialised = true;
    } else {
      console.error('ERROR: User tracking configuration failed');
    }
  }
}
