import { AwsConfig, AwsConfigs } from '../environment.model';
import { REGION } from './region';

export const selectAwsConfig = (configs: AwsConfigs): AwsConfig => {
  const hostname = window.location.hostname;
  if (hostname.endsWith('tmp.ops.twaice.ai')) {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aws_project_region: REGION,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aws_user_pools_id: 'eu-west-1_8RU5YcGMu',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aws_user_pools_web_client_id: '4k1scub4rk04ac6cpnfhfgvpje',
      federatedSignIn: {
        identityProvider: 'Microsoft365EntraIdProvider',
        domain: 'twaice-oidc-ops-internal.auth.eu-west-1.amazoncognito.com',
        redirectUrl: 'https://' + hostname,
        allowedHost: hostname,
      },
      pinpointDisabled: true,
    };
  } else if (hostname.startsWith('internal') || hostname.startsWith('localhost')) {
    return {
      pinpointDisabled: true,
      ...configs.internal_sso,
    };
  } else if (hostname.startsWith('daimlerbus')) {
    return configs.daimlerbus;
  } else {
    return configs.default;
  }
};
