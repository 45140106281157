/* eslint-disable @typescript-eslint/naming-convention -- API defined property names */
import { TWResponse } from './response.models';
import { IsOptional } from 'class-validator';

export class Sensor {
  id: string;
  name?: string;
  container_id?: string;
  system_id?: string;
  type?: string;
  type_id?: number;
  unit: string;
  is_virtual?: boolean;
  is_internal?: boolean;
  id_from_client?: string;
  address_from_client?: string;
  sampling_rate?: number;
  config_data: unknown;

  static getSensorLabel(sensor: Sensor, withUnit = false) {
    let label = sensor.name ? sensor.name : sensor.type;
    if (withUnit && sensor.unit.trim()) {
      label += ` [${sensor.unit}]`;
    }
    return label;
  }
}

export class FESensor extends Sensor {
  label?: string;
}

export class SensorListResponse extends TWResponse {
  data: Sensor[];
}

export class SensorResponse extends TWResponse {
  data: Sensor;
}

export class SensorTypeIDAndUnits {
  typeID: number;
  type: string;
  unit: string;
}

export class SensorsRequestParams {
  @IsOptional()
  exclude_internal?: boolean; // default: true
  @IsOptional()
  recursive?: boolean; // default: false
  @IsOptional()
  includeVirtualSensors?: boolean; // default: false - this is frontend specific
}

export class SensorOnDepthParams {
  @IsOptional()
  page?: number;
  @IsOptional()
  page_size?: number;
  depth: number;
  filter_key: string;
  filter_val: string;
}

export class SensorOnDepthData {
  @IsOptional()
  name: string;
  @IsOptional()
  container_name: string;
  id: string;
  container_id: string;
}
