export class UiConfig {
  customerId: string;
  featureFlags: string[] | FeatureFlagsEnum[];
  customerType: CustomerType;
  solutions: Solution[];
  customerBk: string;
}

export enum CustomerType {
  ENERGY = 'ENERGY',
  MOBILITY = 'MOBILITY',
  MODELS = 'MODELS',
  // Only used as a fallback if config is missing or invalid
  UNKNOWN = 'UNKNOWN',
}

export enum Solution {
  ENERGY_HEALTH = 'ENERGY_HEALTH',
  ENERGY_WARRANTY = 'ENERGY_WARRANTY',
  ENERGY_HEALTH_PREDICTION = 'ENERGY_HEALTH_PREDICTION',
  DATA_EXPLORER = 'DATA_EXPLORER',
  REPORTING = 'REPORTING',
  ENERGY_SAFETY = 'ENERGY_SAFETY',
  MOBILITY = 'MOBILITY',
  MODEL_LIBRARY = 'MODEL_LIBRARY',
  PERFORMANCE_MANAGER = 'PERFORMANCE_MANAGER',
}

export enum FeatureFlagsEnum {
  HEALTH_INCIDENTS_OVERVIEW = 'HEALTH_INCIDENTS-OVERVIEW',
  PERFORMANCE_MANAGER_STRING_OVERVIEW = 'PERFORMANCE_MANAGER_STRING_OVERVIEW',
  PERFORMANCE_MANAGER_BALANCING_LANDING_PAGE = 'PERFORMANCE_MANAGER_BALANCING_LANDING_PAGE',
  PERFORMANCE_MANAGER_TEMPERATURE_LANDING_PAGE = 'PERFORMANCE_MANAGER_TEMPERATURE_LANDING_PAGE',
  ASSET_DASHBOARD_TABLE = 'ASSET_DASHBOARD_TABLE',
  ENERGY_ANALYTICS_SHOW_PREDICTION = 'ENERGY_ANALYTICS_SHOW_PREDICTION',
  FLEET_ANALYTICS_SHOW_WARRANTY_PROTOTYPE = 'FLEET_ANALYTICS_SHOW_WARRANTY_PROTOTYPE',
  FLEET_ANALYTICS_SHOW_COMPARISON_TAGS = 'FLEET-ANALYTICS-SHOW-COMPARISON-TAGS',
  FLEET_ANALYTICS_SHOW_RISK_FACTOR = 'FLEET-ANALYTICS-SHOW-RISK-FACTOR',
  FLEET_ANALYTICS_SHOW_COMPARISON_VIEWS = 'FLEET-ANALYTICS-SHOW-COMPARISON-VIEWS',
  FLEET_ANALYTICS_ENABLE_INCIDENT_DETAIL_PNG_EXPORT = 'FLEET_ANALYTICS_ENABLE_INCIDENT_DETAIL_PNG_EXPORT',
  FLEET_ANALYTICS_HIDE_WARRANTY = 'FLEET_ANALYTICS_HIDE_WARRANTY',
  FLEET_ANALYTICS_ENABLE_SAFETY = 'FLEET_ANALYTICS_ENABLE_SAFETY',
}

export interface ConfigsInterface {
  featureFlags: string[] | FeatureFlagsEnum[];
  solutions: Solution[];
}
