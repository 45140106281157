<button
  nz-button
  nzType="text"
  nzBlock
  nz-dropdown
  [nzDropdownMenu]="timeRangeMenu"
  [nzTrigger]="'click'"
  class="flex items-center gap-8"
>
  <span class="font-normal text-sm leading-base">{{ selectedTimeRange.label }}</span>
  <span nz-icon>
    <twaice-fe-svg-icon name="chevron-down" source="tabler" size="16px" />
  </span>
</button>
<nz-dropdown-menu #timeRangeMenu="nzDropdownMenu">
  <div class="bg-white shadow-default p-24 rounded-lg flex flex-col gap-16">
    <span class="text-base font-semibold">Time range</span>
    <nz-segmented
      [nzOptions]="timeRangeMapping"
      [(ngModel)]="selectedTimeRangeIndex"
      (ngModelChange)="onTimeRangeChange($event)"
    ></nz-segmented>
  </div>
</nz-dropdown-menu>
